import React from 'react'
import "./Testimonials.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import profilePic1 from '../../img/Linh.png'
import profilePic2 from '../../img/Nhung.png'
import profilePic3 from '../../img/Huong.png'
import profilePic4 from "../../img/profile4.jpg";
import { Pagination } from 'swiper';
import 'swiper/css/pagination'
import 'swiper/css'

const Testimonials = () => {
    const clients = [
      {
        img: profilePic1,
        review:
          "Chụp lẹ đi kiếm mấy show để kiếm tiền cho bằng bạn bằng bè 😀.",
      },
      {
        img: profilePic2,
        review: "Cho mượn sổ đỏ đi cắm đi mấy má ơi 😍 ",
      },
      {
        img: profilePic3,
        review:
          `Mình về mình có nhớ ta, Ta về ta nhớ hàm răng mình niềng`,
      },
     
    ];
  return (
    <div className="t-wrapper">
      <div className="t-heading">
        <span>Và đây là </span>
        <span> những người bạn </span>
        <span> của tui ...</span>

        <div
          className="blur t-blur1"
          style={{ background: "var(--purple)" }}
        ></div>
        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
      </div>

      {/* slider */}
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default Testimonials