import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png'
import Glasses from '../../img/glasses.png'
import Humble from '../../img/humble.png'
import Card from '../Card/Card'
import Resume from './a.mp4'
import {motion} from 'framer-motion'

const Services = () => {

  const transition = {duration: 3 , type : 'spring'}

  return (
    <div className="services">
      {/* left side */}

      <div className="awesome">
        <span>Thông tin </span>
        <span>của tui</span>
        <span>
          Nếu bạn muốn xem thêm
          <br />
          Hãy xem ở đây!!!
        </span>
        <a href={Resume} download>
          <button className="button s-button">Download</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>

      {/* right side */}
      <div className="cards">
        <motion.div
          initial={{ left: "25rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
          style={{ left: "14rem" }}
        >
          <Card
            emoji={HeartEmoji}
            heading={"Tính cách"}
            detail={"Tui là một người vui tính, hòa đồng với mọi người."}
          />
        </motion.div>

        {/* second card */}
        <motion.div
          initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
          style={{ top: "12rem", left: "-4rem" }}
        >
          <Card
            emoji={Glasses}
            heading={"Sở thích"}
            detail={"Thích ăn ngon, mặc đẹp và thích đi du lịch nứa !!"}
          />
        </motion.div>

        {/* 3rd card */}
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
          style={{ top: "19rem", left: "12rem" }}
        >
          <Card
            emoji={Humble}
            heading={"Ước mơ"}
            detail={
              "Có thể làm luật sư để bắt những kẻ xấu :)))"
            }
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
}

export default Services