import React from 'react'
import "./Experience.css";

const Experience = () => {
    return (
      <div className="experience">
        <div className="achievement">
          <div className="circle">18</div>
          <span>Ngày</span>
          <span>Experience</span>
        </div>
        <div className="achievement">
          <div className="circle">04</div>
          <span>Sinh</span>
          <span>Projects</span>
        </div>
        <div className="achievement">
          <div className="circle">2002</div>
          <span>Nhật</span>
          <span>Experience</span>
        </div>
      </div>
    );
}

export default Experience