import React from 'react'
import './Floating.css'
import { themeContext } from '../../Context'
import { useContext } from 'react'
const Floating = ({ image, txt1 }) => {
  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode

  return (
      <div className='floatingdiv'>
          <img src={image} />
          <span style={{color:darkMode ? 'black' : ''}}>
          {txt1 }
 
          </span>
      </div>
  )
}

export default Floating