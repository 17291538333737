import React from 'react'
import './Works.css'
import Upwork from '../../img/em2.png'
import Fiverr from '../../img/em1.png'
import Amazon from '../../img/em5.png'
import Shopify from '../../img/em4.png'
import Facebook from "../../img/em3.png";
import { motion } from "framer-motion";


const Works = () => {
  return (
    <div className="works">
      <div className="awesome">
        <span className="work1">Còn đây là tui </span>
        <span className="work2">khi :</span>
        <span className="work3">
          - Khi đi biển
          <br />
          - Khi đi quân sự
          <br />
          - Khi đi sinh nhật
          <br />
          - Khi đi cafe 
        </span>

        <button className="button s-button">Hire me</button>

        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right side */}
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={Upwork} />
          </div>
          <div className="w-secCircle">
            <img src={Fiverr} />
          </div>
          <div className="w-secCircle">
            <img src={Amazon} />
          </div>
          <div className="w-secCircle">
            <img src={Shopify} />
          </div>
          <div className="w-secCircle">
            <img src={Facebook} />
          </div>
        </motion.div>

        {/* background Circles */}
        <div className="w-bacCircle blueCircle"></div>
        <div className="w-bacCircle yellowCircle"></div>
      </div>
    </div>
  );
}

export default Works